// Vendor files


// require('./vendor/jquery-1.11.1.min');

var $ = window.jQuery = window.$ = require('./vendor/jquery-1.11.1.min');

var $$_ = window.$$_ = require('./shared/core'); 

require('./vendor/slick'); 

// require('./vendor/parallax.min');

// var Isotope = require('../_gulp/node_modules/isotope-layout');

// include your scripts here

// require('./shared/textarea-autosize'); 
// require('./shared/img'); 
// require('./shared/map'); 
// require('./shared/parallax'); 
// require('./shared/select'); 
// require('./shared/search'); 

// expose your functions to the global scope for testing
var mxm = {},
_viewing;

// init some things
$(function($){
	var $window = $(window),
	$body = $('body'),
	$main = $('.main'),
	$navigation = $('.navigation'),
	$logo = $('.logo'),
	$shownTarget,

	$markets = [],

	sanitizeHash = (h) => {
		// addUmlauts (s) {
		var umlautTable = {
			"ä": "ae",
			"Ä": "Ae",
			"ü": "ue",
			"Ü": "Ue",
			"ö": "oe",
			"Ö": "Oe",
		},

		_h = h.replace(/(ä)|(Ä)|(ü)|(Ü)|(ö)|(Ö)/g, function(umlaut) { return umlautTable[umlaut] });

		_h = _h.replace(/\s/g, '_');

		
		return _h.toLowerCase();
		// },
	}

	$body.addClass('pageLoaded');

	$main.on('init', function(slick) {
		if (location.hash) {
			let theHash = location.hash.split('#')[1];
			slideIndex = $markets.indexOf(theHash);

			setTimeout(() => {
				$main.slick('slickGoTo', slideIndex);
			}, 100);
		}
	})

	$main.slick({
		prevArrow: '<div class="slick-prev"></div>',
		nextArrow: '<div class="slick-next"></div>',
		dots: true,
		customPaging : function(slider, i) {
			if (i === 0){
	      $markets.push('home');
				return '<div class="nav_logo"></div>';
			}else{
	      var thumb = $(slider.$slides[i]).data('slide');
	      $markets.push(sanitizeHash(thumb))
	      return '<span>'+thumb+'</span>';
			}
    },
    appendDots: '.navigation',
	});

	$$_.mediaQuery.subscribe(function(){
		if (
			$$_.mediaQuery.is('mobile') || 
			$$_.mediaQuery.is('tablet')
		){
			$body.removeClass('showNav');
		// }else if ($main.slick('slickCurrentSlide') !== 0){
		// 	$body.addClass('showNav');
		}else{
			$body.removeClass('showNav');			
		}
	})

	// $main.on('afterChange', function(slick, currentSlide){
	// 	// console.log(slick, currentSlide, nextSlide)

	//   $main.attr('data-slide', currentSlide);
	// }); 

	$('.main_item_interior.first').on('click', function(){
		$main.slick('slickNext');
	});

	var _heightSetter = function(){

		var _infoHeight = 0;

		$('.info_interior').each(function(){
			var $that = $(this);

			if ($that.outerHeight(true) > _infoHeight){
				_infoHeight = $that.outerHeight(true);
			}

			// console.log(_infoHeight, $that.outerHeight(true))
		});

		if ($window.width() > 992){
		// if ($window.width() > 768){
			$('.info_interior').css('height', _infoHeight);
			$('.first_text').css('height', _infoHeight + 70);
			$('.slick-arrow').css('bottom', _infoHeight + 70)
		}else{
			$('.info_interior').css('height', 'auto');			
			$('.first_text').css('height', 'auto');
			$('.slick-arrow').css('bottom', '50%')
		}

	};

	_heightSetter();

	var _sliderReset = $$_.debounce(function(){
		$main.slick('setPosition');
	}, 500);

	$window.resize(function(){
		_heightSetter();

		_sliderReset();
	});

	

	$window.on('hashchange',function(){
		console.log(location.hash)

		// let slideIndex = $markets.indexOf(location.hash);


	    // $('h1').text(location.hash.slice(1));
	});

	$main.on('beforeChange', function(slick, currentSlide, nextSlide){
		console.log(slick, currentSlide, nextSlide)

	  // $main.attr('data-slide', nextSlide);
	  // $body.attr('data-slide', nextSlide)
	}); 

	$main.on('afterChange', function(slick, currentSlide){
	  // console.log(slick, currentSlide, currentSlide.currentSlide);

	  $body.attr('data-slide', currentSlide.currentSlide)

	  // if ($window.width() > 992){
		 //  if (currentSlide.currentSlide !== 0){
		 //  	$body.addClass('showNav');
		 //  }else{
		 //  	$body.removeClass('showNav');	  	
		 //  }
	  // }else{
	  	$body.removeClass('showNav');

	  	console.log(slick, currentSlide.currentSlide, $markets)

	  	window.location.hash = $markets[currentSlide.currentSlide]
	  // }


    // var _nextIsShowing = $main.find('.slick-active').hasClass('showing');

    // if (_nextIsShowing){
  	 //  $body.addClass('showing');
    // }else{
  	 //  $body.removeClass('showing');
    // }
	});

	$('.nav_menu').on('click', function(){
	  $body.toggleClass('showNav');		
	});

	// $main.find('.main_item_interior').on('scroll', function(e){
	// 	var $that = $(this);
	// 	$that.find('.main_item_image').css('background-position-y', ($that.scrollTop() / 2));
	// });

	// $main.find('.main_item_button').on('click', function(){
	// 	var $that = $(this);

	// 	$shownTarget = $that.parent();

	// 	$shownTarget.addClass('showing');

	// 	$body.addClass('showing');

	// 	var _scrollTarget;

	// 	if ($window.width() > 1200){
	// 		_scrollTarget = $(window).height() + 1
	// 	}else{
	// 		_scrollTarget = $(window).height() - ($logo.height() + 40);
	// 	}

	// 	$shownTarget.animate({
	// 		scrollTop: _scrollTarget
	// 	}, 1000);
	// });

	// $('.nav_point_about').on('click', function(){
	// 	$('.section.hello').addClass('showing');
	// });

	// $('.nav_point_contact').on('click', function(){
	// 	$('.section.contact').addClass('showing');
	// });

	// $('.hello_close').on('click', function(){
	// 	$('.section.hello').removeClass('showing');
	// });

	// $('.contact_close').on('click', function(){
	// 	$('.section.contact').removeClass('showing');
	// });

	// $logo.on('click', function(){
	// 	$shownTarget.animate({
	// 		scrollTop: 0
	// 	}, 1000);
	// });
});




// template for new JS files, a la browserify
$(function($){

});



















